
import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

export default Vue.extend({
  name: "DashboardPersonal",

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    loaded: false as boolean,
    model: {} as any,
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  computed: {
    rules() {
      return {
        required: [
          (v: string): boolean | string | LocaleMessages =>
            !!v || this.$t("global_validation.required")
        ]
      };
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<any> {
      try {
        const response = await this.$API.users().getUser();

        this.model = {
          ...response.data
        };
      } catch (e) {
        await this.$store.dispatch("alerts/showError", "Произошла ошибка");
      }
    },
    async submit(): Promise<any> {
      this.loaded = true;
      try {
        if ((this.$refs.form as Vue).validate()) {
          const model = { ...this.model };

          await this.$API.users().edit(model.id, model);
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch(
          "alert/showError",
          "Пройзошла ошибка при редактировании."
        );
      }
      this.loaded = false;
    }
  }
});
